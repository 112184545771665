import { useRef } from 'react';
import { ZDialog } from '@zep/ui';
import { Button } from '@zep/ui_v3';
import { useTranslation } from 'next-i18next';
import { overlay } from 'overlay-kit';
interface Props {
  title: string;
  content?: string;
  open: boolean;
  onClose?: () => void;
  onConfirm?: () => Promise<unknown>;
}
export function AlertDialog({
  title,
  content,
  open,
  onClose
}: Props) {
  const {
    t
  } = useTranslation();
  return <ZDialog.Dialog maxWidth="320px" open={open} onClose={() => onClose?.()} data-sentry-element="unknown" data-sentry-component="AlertDialog" data-sentry-source-file="useAlertDialog.tsx">
      <ZDialog.DialogOverlay data-sentry-element="unknown" data-sentry-source-file="useAlertDialog.tsx" />
      <ZDialog.DialogPanel className="pt-2" data-sentry-element="unknown" data-sentry-source-file="useAlertDialog.tsx">
        <ZDialog.DialogHeader className="text-heading-md-bold" data-sentry-element="unknown" data-sentry-source-file="useAlertDialog.tsx">
          {title}
        </ZDialog.DialogHeader>
        <ZDialog.DialogContent className="!px-6 text-body-md-regular" data-sentry-element="unknown" data-sentry-source-file="useAlertDialog.tsx">
          {content}
        </ZDialog.DialogContent>
        <ZDialog.DialogFooter className="!px-6" data-sentry-element="unknown" data-sentry-source-file="useAlertDialog.tsx">
          <div className="flex gap-2">
            <Button className="flex-1" intent="destructive" size="md" variant="contained" onClick={onClose} data-sentry-element="Button" data-sentry-source-file="useAlertDialog.tsx">
              {t('common.alertDialog.confirmText', '확인')}
            </Button>
          </div>
        </ZDialog.DialogFooter>
      </ZDialog.DialogPanel>
    </ZDialog.Dialog>;
}
export function useAlertDialog() {
  const overlayKey = useRef<string | null>(null);
  const didOpen = useRef(false);
  const open = async ({
    title,
    content
  }: {
    title: string;
    content?: string;
  }) => {
    didOpen.current = true;
    return new Promise<void>(resolve => {
      overlayKey.current = overlay.open(({
        isOpen,
        close
      }) => {
        return <AlertDialog title={title} content={content} open={isOpen} onClose={() => {
          didOpen.current = false;
          close();
          resolve();
        }} />;
      });
    });
  };
  const close = () => {
    if (overlayKey.current != null) {
      overlay.close(overlayKey.current);
      overlayKey.current = null;
      didOpen.current = false;
    }
  };
  return {
    open,
    close,
    didOpen
  };
}